* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Sora", sans-serif;
}

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

a:active {
  color: black;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 16px;
  font-weight: 700;
}

h4 {
  font-size: 0.8em;
}

p {
  font-size: 16px;
}

p2 {
  font-size: 12px;
}

.NavbarContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Navbar {
  height: 60px;
  padding: 20px 50px;
  border-radius: 0 0 5px 5px;
  background: white;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 90vw;
  -webkit-box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.22);
}

.logo {
  height: 20px;
}

.navi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

a {
  cursor: pointer;
}

a:visited {
  color: inherit;
}

.navi * {
  margin-left: 32px;
  user-select: none;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
}

.navi > a:hover {
  opacity: 0.3;
  cursor: pointer;
}

.navi h2 {
  cursor: pointer;
}

.Landing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: 35vh;
  overflow-x: hidden;
}

.title {
  color: black;
}

.Landing .left {
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.subtitle {
  margin-top: 16px;
  margin-bottom: 24px;
  line-height: normal;
}

.Landing .right {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Landing img {
  width: 40vw;
}

.Landing .mockup {
  z-index: 2;
  position: absolute;
  margin-top: 5vh;
}

.Landing .shape {
  position: absolute;
  z-index: 1;
  margin-left: 15vw;
  margin-top: -5vh;
}

button {
  border-radius: 3px;
  font-weight: 600;
  font-size: 1em;
  color: white;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

button:hover {
  box-shadow: 0 14px 28px rgba(86, 210, 145, 0.25),
    0 10px 10px rgba(86, 210, 145, 0.22);
  padding: 20px 40px;
  border-radius: 10px;
}

.button-sm {
  padding: 7px 15px;
  font-size: 0.8em;
  background-color: #4bc281;
}

.button-sm:hover {
  box-shadow: 0 14px 28px rgba(86, 210, 145, 0.25),
    0 10px 10px rgba(86, 210, 145, 0.22);
  padding: 12px 23px;
  border-radius: 10px;
}

.basic {
  background-color: rgb(86, 210, 145);
  padding: 15px 30px;
  box-shadow: 0 1px 3px rgba(86, 210, 145, 0.12),
    0 1px 2px rgba(86, 210, 145, 0.24);
  transition: 300ms;
}

.basic:active {
  background-color: rgb(92, 153, 121);
}

.minimal {
  background-color: none;
  color: rgb(86, 210, 145);
}

.minimal:hover {
  text-decoration: underline;
}

.Landing > * {
  margin-bottom: 16px;
}

.Info1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50vh 0;
}

.bigContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Info1 .container {
  width: 60vw;
  background-color: #242424;
  border-radius: 5px;
  padding: 64px;
  z-index: 2;
  box-shadow: 0px 4px 27px rgba(75, 194, 129, 0.35);
}

.Info1 .greendeco {
  width: 62vw;
  background-color: rgb(86, 210, 145);
  border-radius: 5px;
  padding: 48px;
  position: absolute;
  z-index: 1;
}

.Info1 .greendeco * {
  opacity: 0;
}

.Info1 * {
  color: white;
}

.Info1 .container > p {
  margin-bottom: 32px;
}

.Info1 .greendeco > p {
  margin-bottom: 32px;
}

.Info1ElementsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.Info1Element {
  width: 40%;
  margin-bottom: 32px;
}

.Info1Element img {
  width: 50px;
}

.Info1 .icon {
  margin-bottom: 16px;
}

.Info2 {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-bottom: 200px;
}

.Info2Element {
  margin-bottom: 32px;
}

.Info2Container {
  width: 60vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.module-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
}

.module {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.text-container {
  width: 300px;
}

.module-container h1 {
  font-size: 1.5em;
}

.module-container p {
  font-size: 0.7em;
}

.Info3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  margin-top: 35vh;
}

.title {
  color: black;
}

.Info3 .left {
  width: 35vw;
  display: flex;
  flex-direction: column;
}

.Info3 .right {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Info3 img {
  width: 40vw;
  margin-right: 16px;
}

.Info3 .mockup {
  z-index: 2;
  position: absolute;
  margin-top: 5vh;
}

.Info3 .shape {
  position: absolute;
  z-index: 1;
  margin-left: 15vw;
  margin-top: -5vh;
}

.Info4 .big-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 400px 0;
  padding: 0 20vw;
}

.Info4 img {
  width: 300px;
  height: 280px;
}

.Info4 .container {
  width: 30vw;
}

.Info4 .container > * {
  margin-bottom: 20px;
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20vw;
}

.Form .title {
  margin-bottom: 16px;
  text-align: center;
}

.Form .subtitle {
  text-align: center;
  width: 40vw;
  margin-bottom: 32px;
}

.Form input {
  width: 30vw;
  height: 50px;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 1em;
  margin-bottom: 8px;
  border: 2px solid #242424;
}

.Form input:focus {
  outline: none;
}

.Form form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Footer {
  width: 100vw;
  padding: 50px 10vw;
  background: #242424;
}

.Footer img {
  width: 100px;
  margin-bottom: 8px;
}

.Footer * {
  color: white;
}

.FooterContainer {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 992px) {
  /* For mobile phones: */
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 12px;
  }

  .Info1 .container {
    width: 80vw;
  }

  .Info1 .greendeco {
    width: 85vw;
  }

  .Info2Container {
    width: 80vw;
  }

  .Form .subtitle {
    width: 70vw;
  }

  .Form input {
    width: 70vw;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .Landing {
    flex-direction: column-reverse;
    margin-top: 25vh;
  }

  .Landing .left {
    width: 90vw;
  }

  .Landing .right {
    width: 90vw;
  }

  .Landing img {
    width: 70vw;
  }

  .Landing .mockup {
    z-index: 2;
    position: initial;
  }

  .Landing .shape {
    position: absolute;
    z-index: 1;
    margin-left: 15vw;
    margin-top: -5vh;
  }

  .Info1 {
    margin: 25vh 0;
  }

  .Info1Element {
    width: 80%;
    margin-bottom: 32px;
  }

  .Info1 .container {
    width: 95vw;
    padding: 32px;
  }

  .Info1 .greendeco {
    display: none;
  }

  .Info3 {
    flex-direction: column;
    margin-top: 25vh;
  }

  .Info3 img {
    width: 90vw;
    margin-bottom: 32px;
  }

  .Info3 .left {
    width: 90vw;
  }

  .Info3 .right {
    width: 90vw;
  }
  .Form {
    padding: 100px 0;
  }

  .Form .subtitle {
    width: 90vw;
  }

  .Form input {
    width: 90vw;
    margin-bottom: 16px;
  }

  .Navbar {
    width: 100vw;
  }
}
